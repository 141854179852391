<template>
    <div class="page-booking">
        <TopBar :showBack="true" title="Отложенный заказ" />

        <div class="scrollview" style="padding-bottom:70px;padding-top:20px">
            <booking-restoran-input title="Кафе:" v-model="restoran" :items="restorans" v-show="restorans.length"/>
        </div>
        <div class="btn-submit" v-show="allowbooking" @click="tapSubmit">
            Перейти к меню
        </div>
        <v-overlay :value="proccessing">
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
import TopBar from '../components/TopBar.vue';
import BookingRestoranInput from '../components/BookingRestoranInput.vue';

export default {
    components: {
        TopBar,
        BookingRestoranInput
    },
    data(){
        return {
            proccessing: false,
            restorans: [],
            restoran: false,
            allowbooking: false,
            min_sum: null,
            deliveryDateTimeProps: null
        }
    },
    watch: {
        restoran(){
            this.loadData()
        }
    },
    created(){
        this.loadData();
    },
    methods: {
        tapSubmit(){
            if(this.proccessing) return;

            this.proccessing = true;
            this.getRestorans().then(()=>{
                this.proccessing = false;
                this.$store.state.deliveryDateTimeProps = this.deliveryDateTimeProps;

                const restoran = this.$store.state.restorans.filter(item => item.fs_uid == this.restoran.id)[0];
        
                this.$store.state.tableId = '';
                this.$store.state.booking_num = '';
                this.$store.state.booking_min_sum = '';
                this.$store.commit('setRestoran', restoran);
                this.$store.commit('orderType', 'inrest');
                this.$router.push('/menu/'+this.$store.state.restoran.fs_uid+'/inrest')
            });
        },
        loadData(){
            if(this.proccessing) return;
            this.proccessing = true;
            
            this.request({
                action: 'delayedzakazRestorans',
                fields: {
                    restoranid: this.restoran.id
                }
            }).then(r => {
                if(r.restorans)
                    this.restorans = r.restorans;

                if(typeof r.deliveryDateTimeProps != 'undefined')
                    this.deliveryDateTimeProps = r.deliveryDateTimeProps;
                
                if(typeof r.allowbooking != 'undefined')
                    this.allowbooking = r.allowbooking;
                
                this.proccessing = false;
            }).catch(() => {
                this.allowbooking = false;
                this.proccessing = false;
                alert(this.$error502);
            })
        }
    }
}
</script>

<style scoped>
.btn-submit {
    height: 55px;
    color: #fff;
    background-color:#0071E5;
    border-radius: 40px;
    margin: 0px;
    position: fixed;
    bottom: 10px;
    left: 10px;
    right: 10px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
</style>