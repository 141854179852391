<template>
    <div class="page-restmap">
        <TopBar title="Карта кафе" :showBack="true" />
        <v-overlay :value="proccessing">
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>
        <yandex-map :coords="coords" :controls="controls" :zoom="zoom" @map-was-initialized="onMapInit"></yandex-map>
    </div>
</template>

<script>
import TopBar from '../components/TopBar.vue';
export default {
    components: {TopBar},
    data(){
        return {
            coords: [55.755864, 37.617698],
            zoom: 10,
            controls: [
                'zoomControl','geolocationControl','searchControl'
            ],
            map: null,
            proccessing: true,
            items: [],
            loaded: false
        }
    },
    methods: {
        onMapInit(map){
            this.map = map;

            this.getRestorans().then(() => {
                this.loadItems();
            })
        },
        loadItems(){
            this.items = [];
            this.$store.state.restorans.forEach(item => {
				item.worknow = this.isWorkNow(item.shedule);
                if(item.deliveryRest == '1' || item.deliverySelf){
                    const loc = JSON.parse(item.location);

                    item.deliveryPoint = new window.ymaps.GeoObject({
                        geometry: {type: 'Point',
                        coordinates: [loc[0], loc[1]]
                        },
                        properties: {iconCaption: item.nameShort,
                        balloonContentHeader:item.name,
                        balloonContentBody:`<p>${item.address}</p>${item.about?'<p>'+item.about+'</p>':''}<a href="tel://${item.phone}" class="btn-map"><i class="fas fa-phone"></i> Позвонить</a> <a href="mailto://${item.email}" class="btn-map"><i class="fas fa-envelope"></i> Написать</a>`
                        }
                    }, {
                        preset: 'islands#blueDotIconWithCaption',
                        iconCaptionMaxWidth: '215'
                    })

                    this.map.geoObjects.add(item.deliveryPoint);
                    
                    this.items.push(item);
				}
			})
			this.loaded = true;
			this.proccessing = false;
        }
    }
}
</script>

<style lang="scss" scoped>
.page-restmap {

}

.ymap-container {
    position: fixed;
    top: 60px;
    left: 0;
    right: 0;
    bottom: 0;
}
</style>