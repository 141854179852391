<template>
    <div class="page-zagruzka">
        <TopBar :showBack="true" :showMenu="false" />
        <div class="p-heading">
            <img src="../assets/icon-type-svetofor-w.png" height="56px"/>
            <span class="p-title">Загруженность <br>кафе</span>
        </div>

        <div row="2">
            <div class="p-info">{{textInfo}}</div>
        </div>

        <div class="p-btn" @click="onTapSubmit">Далее</div>
    </div>
</template>

<script>
import TopBar from '/src/components/TopBar';

export default {
    components: {
        TopBar
    },
    data(){
        return {
            textInfo: this.$store.state.settings.text_about_zagruzka
        }
    },
    methods: {
        onTapSubmit(){
            this.$router.push('/zagruzka/rest_list');
        }
    }
}
</script>

<style scoped lang="scss">
@import '/src/assets/styles/app.scss';
.page-zagruzka {
    @extend .bg-inversed;

    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.p-title {
    font-size: 24px;
    font-weight: 700;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
}

.p-info {
    font-size: 14px;
    font-weight: 700;
    color: #fff;
    margin: 0 20px 30px 30px;

    white-space: break-spaces;
    line-height: 1.4;
}

.p-btn {
    border-radius: 50px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    text-transform: uppercase;
    background: #fff;
    color: #0071E5;
    margin: 5px 10px 5px 10px;
    font-weight: bold;
    font-size: 14px;
}

.p-heading {
    display: flex;
    justify-content: center;
    align-items: end;
    margin: 20px 0;
}
</style>