<template>
    <div class="page-main">
        <TopBar :showMenu="!$store.state.kiosk" />
        <div class="logo">
            <img src="../assets/logo-wt.png" />
            <div style="color:#ffffff;font-size:13px;font-weight: bold;text-align: center;">КАФЕ - МАГАЗИН</div>
        </div>
        <div v-show="isItemsLoaded && $store.state.kiosk" class="box-types">
            <div class="box-types--label mb-20px">
                Возьмите номерок и укажите в поле:
            </div>
            <div class="mb-20px">
                <input type="tel" placeholder="_ _ _" class="table-number-input" v-model="kioskTableNumber">
            </div>
            <div style="margin:0 20px 100px 20px">
                <table-number-input v-model="kioskTableNumber" @submit="onTableNumSubmit" />
            </div>
        </div>
        <div v-show="isItemsLoaded && !$store.state.kiosk" class="box-types">
            <div class="box-types--label">
                Как хотите сделать заказ?
            </div>
                <div class="order-type-list">
                    <div col="0" rows="95,40" class="order-type-item" @click="tapInRestModal" :style="{width:btnWidth}">
                        <div class="icon">
                            <img src="../assets/icon-type-inrest-w.png" width="52" row="0" verticalAlignment="center"/>
                        </div>
                        <span>КАФЕ</span>
                    </div>
                    <div col="0" rows="95,40" class="order-type-item" @click="tapSelf" :style="{width:btnWidth}">
                        <div class="icon">
                            <img src="../assets/icon-type-self-w.png" width="52" row="0" verticalAlignment="center"/>
                        </div>
                        <span>ЗАБЕРУ САМ</span>
                    </div>
                    <div col="0" rows="95,40" class="order-type-item" @click="tapDeliveryNew" :style="{width:btnWidth}">
                        <div class="icon">
                            <img src="../assets/icon-type-delivery-w.png" width="52" row="0" verticalAlignment="center"/>
                        </div>
                        <span>ДОСТАВКА</span>
                    </div>
                </div>
            <div class="box-types--label">
                НАШИ СЕРВИСЫ
            </div>
                <div class="order-type-list-scroll scrollbar-hidden">
                    <div col="0" rows="95,40" class="order-type-item" @click="tapRestPredzakaz" v-if="$store.state.settings.allow_predzakaz"  :style="{width:btnWidth}">
                        <div class="icon">
                            <img src="../assets/icon-type-predzakaz-w.png" width="52" row="0" verticalAlignment="center"/>
                        </div>
                        <span>Предзаказ в кафе</span>
                    </div>
                    <div col="0" rows="95,40" class="order-type-item" @click="tapBooking" v-if="$store.state.user.booking==1"  :style="{width:btnWidth}">
                        <div class="icon">
                            <img src="../assets/icon-type-book-w.png" width="52" row="0" verticalAlignment="center"/>
                        </div>
                        <span>БРОНИРОВАТЬ СТОЛ</span>
                    </div>
                    <div col="0" rows="95,40" class="order-type-item" :style="{width:btnWidth}" @click="onTapZagruzka" v-if="$store.state.settings.show_zagruzka==1" >
                        <div class="icon">
                            <img src="../assets/icon-type-svetofor-w.png" width="52" row="0" verticalAlignment="center"/>
                        </div>
                        <span>загруженность КАФЕ</span>
                    </div>
                    <div col="0" rows="95,40" class="order-type-item" :style="{width:btnWidth}" @click="onTapMap" v-if="$store.state.settings.show_restmap==1" >
                        <div class="icon">
                            <img src="../assets/icon-type-location-w.png" width="52" row="0" verticalAlignment="center"/>
                        </div>
                        <span>Карта Кафе</span>
                    </div>
                    <div col="0" rows="95,40" class="order-type-item" :style="{width:btnWidth}" v-if="$store.state.user.stoplist" @click="onTapStop">
                        <div class="icon">
                            <img src="../assets/icon-type-stop-w.png" width="52" row="0" verticalAlignment="center"/>
                        </div>
                        <span>Стоп-лист</span>
                    </div>
                </div>
                <div class="btn-row">
                    <a class="btn-row-item hotline" :href="appUrl">
                        <!-- <Label :text="'\uf879'" class="fas" padding="10 7 10 20"/> -->
                        <i class="fab fa-app-store" v-if="platform=='ios'" style="font-size:20px"></i>
                        <i class="fab fa-google-play" v-else></i>
                        <span>Приложение</span>
                    </a>
                    <div class="btn-row-item hotline" @click="onTapHotline">
                        <!-- <Label :text="'\uf879'" class="fas" padding="10 7 10 20"/> -->
                        <i class="fa fa-phone-alt"></i>
                        <span>Горячая линия</span>
                    </div>
                </div>
        </div>
        <AddressListModal :visible="addressModalVisible" @hide="addressModalVisible=false"
            @input="onAddressSelect" :items="addressList"
        />
        <v-dialog
            v-model="dialog" persistent
            >
            <v-card class="box-qr-info" style="margin:0">
                <v-card-title class="text-h5">
                    Необходимо привязать заказ
                </v-card-title>
                <v-card-text>Для оформления заказа<br>отсканируйте QR-код со стола
                <br><br>
                <qrcode-stream style="height:160px" v-if="dialog" @decode="onDecodeQr"></qrcode-stream>
                <!-- <img  src="../assets/icon-qr-phone.png" width="90px"/> -->
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="white darken-1"
                    text
                    @click="dialog = false"
                >
                    Отмена
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-overlay :value="proccessing">
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
import TopBar from '/src/components/TopBar';
import AddressListModal from '../components/AddressListModal';
import { QrcodeStream } from 'vue-qrcode-reader';
import TableNumberInput from '../components/TableNumberInput.vue'
import platform from 'platform-detect';

export default {
    components: {
        TopBar,
        QrcodeStream,
        AddressListModal,
        TableNumberInput
    },
    data() {
        return {
            msgLabel: "Важное объявление!\nЗавтра выходной, после 19:00",
            items: [],
            isItemsLoaded: true,
            errorText: '',
            lastOrder: {},
            proccessing: false,
            dialog: false,
            addressModalVisible:false,
            addressList:[],
            kioskTableNumber: '',
            btnWidth: 0,
            platform: 'android'
        };
    },
    computed: {
        appUrl(){
            if(this.platform == 'ios'){
                return 'https://apps.apple.com/ru/app/%D0%BC%D0%BE%D1%80%D0%B5%D0%BC%D0%B0%D0%BD%D0%B8%D1%8F-next/id1479982679';
            }else{
                return 'https://play.google.com/store/apps/details?id=ru.moremania.techboss';
            }
        }
    },
    created(){
        if(this.$store.state.kiosk){
            this.stopActivitiDetector();
            
            this.proccessing = true;
            this.getRestorans().then(()=>{
                const key = this.$store.state.restorans.findIndex(item => item.fs_uid == this.$store.state.restoran.fs_uid);
                this.$store.commit('setRestoran', this.$store.state.restorans[key])
                this.proccessing = false;
            })
        }

        this.btnWidth = Math.ceil((window.innerWidth - 40) / 3) + 'px';

        if(platform.ios || platform.macos){
            this.platform = 'ios'
        }
    },
    methods: {
        tapRestDelayedzakaz(){
            console.log('tapRestDelayedzakaz');
            // this.$navigateTo(this.$routes.Delayedzakaz, {
            //     backstackVisible: false
            // });
            this.$router.push('/delayedzakaz')
        },
        tapRestPredzakaz(){
            console.log('tapRestPredzakaz');
            // this.$navigateTo(this.$routes.Predzakaz, {
            //     backstackVisible: false
            // });
            this.$router.push('/predzakaz')
        },
        tapInRestModal(){
            this.$store.state.deliveryDateTimeProps = null;

            if(!this.$store.state.settings.allow_predzakaz){
                this.tapInRest();
                return;
            }

            this.showDialog({
                items: [
                    'Отсканировать позже',
                    'Отсканировать сейчас'
                ],
                active: 1,
                title: 'Заказ в кафе',
                message: 'Отсканируйте код на столе, если хотите заказать сейчас или выберите предзаказ, если хотите прийти в кафе позже'
            }).then(i => {
                if(i === 0){
                    this.tapRestDelayedzakaz();
                    // this.tapRestPredzakaz();
                }else if(i == 1){
                    // this.onDecodeQr(360309);
                    // this.scanTableCode();
                    this.tapInRest();
                }
            });
        },
        onTapStop(){

        },
        onTapMap(){
            this.$router.push('/rest_map')
        },
        onTapZagruzka(){
            this.$router.push('/zagruzka')
        },
        onTableNumSubmit(){
            if(this.kioskTableNumber.length <= 0) return;

            this.initActivityDetector();

            this.$store.state.tableId = this.kioskTableNumber;
            this.$store.state.booking_num = '';
            this.$store.state.cart = [];
            this.$store.commit('orderType', 'inrest');
            this.$router.push('/menu/'+this.$store.state.restoran.fs_uid+'/inrest')
        },
        onDecodeQr(code){
            this.dialog = false;
            this.proccessing = true;

            this.getRestorans().then(()=>{
                fetch(this.$serverUrl + '/api/v1/restoran/table/' + encodeURIComponent(code))
                .then(res => res.json())
                .then(res => {
                    if(res.num)
                    {
                        const key = this.$store.state.restorans.findIndex(item => item.fs_uid == res.fs_uid);

                        if(key < 0 
                            || this.$store.state.restorans[key].active != '1' 
                            || this.$store.state.restorans[key].deliveryRest != '1')
                        {
                            this.showDialog({
                                title: 'Заказ невозможен',
                                message: 'Заказ за столом в этом кафе сейчас невозможен',
                                items: ['Ok']
                            });
                        }
                        else
                        {
                            this.$store.state.restorans[key].worknow = this.isWorkNow(this.$store.state.restorans[key].shedule);

                            if(!this.$store.state.restorans[key].worknow)
                            {
                                this.showDialog({
                                    title: 'Заказ невозможен',
                                    message: 'Кафе сейчас закрыто',
                                    items: ['Ok']
                                });
                            }
                            else
                            {
                                this.$store.state.tableId = code;
                                this.$store.state.orderType = 'inrest';
                                this.$store.state.booking_num = '';
                                this.$store.commit('setRestoran', this.$store.state.restorans[key])
                                this.$store.commit('orderType', 'inrest');
                                // this.goto(this.$routes.Main)
                                this.$router.push('/menu/'+res.fs_uid+'/inrest')
                            }
                        }
                    }
                    else
                    {
                        this.showDialog({
                            title: 'Ошибка',
                            message: 'Неверный номер стола!',
                            items: ['Ok']
                        });
                    }
                }).catch(()=>{
                    this.showDialog({
                            title: 'Ошибка',
                            message: 'Неверный номер стола!',
                            items: ['Ok']
                        });
                        this.proccessing = false;
                })
            })
        },
        tapInRest(){
            this.dialog = true;
        },
        tapSelf(){
            this.$store.state.orderType = 'self';
            this.$router.push({name:'SelfFrom'})
        },
        tapDeliveryNew(){
            if(this.proccessing) return;
            this.addressList = [];
            this.proccessing = true;
            this.getUserAddresses().then(res => {
                this.proccessing = false;
                if(!res.success || !res.result.length){
                    this.$router.push({name:'DeliveryTo'})
                    return;
                }else{
                    res.result.forEach(el => {
                        if(this.$store.state.curAddress.id && el.id == this.$store.state.curAddress.id){
                            el.active = true;
                        }else{
                            el.active = false;
                        }
                        this.addressList.push(el);
                    })
                    this.addressModalVisible = true;
                }
            }).catch(()=>{
                this.proccessing = false;
                this.$router.push({name:'DeliveryTo'})
            })
        },
        tapBooking(){
            if(this.proccessing) return;
            this.$store.commit('emptyCart');
            this.proccessing = true;
            this.request({action: 'bookingList', fields: {
                active: 1
            }}).then(r => {
                if(!r.items || !r.items.length)
                {
                    this.$router.push('/booking');
                }
                else
                {
                    // console.log(r.items[0])
                    this.$router.push({
                        name:'BookingResult', 
                        params: {
                            booking: r.items[0]
                        }
                    });
                }
            }).catch(() => {
                this.proccessing = false;
                alert(this.$error502);
            })
        },
        onTapHotline(){
            window.location.href = "tel://" + this.$store.state.settings.hotline;
        },
        onAddressSelect(item){
            this.addressModalVisible = false;
            if(!item.id){
                this.$router.push({name:'DeliveryTo'})
            }else{
                this.proccessing = true;
                this.getRestorans().then(()=>{
                    const zoneDataObj = this.getLocationZone(item.lon*1, item.lat*1);    
                    this.$store.commit('setRestoran', zoneDataObj.restoran)
                    this.$store.commit('orderType', 'delivery');
                    this.$store.state.curAddress = item;
                    this.$store.state.curZone = zoneDataObj.zone;
                    this.$store.state.cart = [];
                    this.proccessing = false;
                    this.$router.push('/delivery_time')
                }).catch(()=>{
                    this.proccessing = false;
                })
            }
        }
    }
}
</script>

<style scoped lang="scss">
@import '/src/assets/styles/app.scss';
.page-main {
    @extend .bg-inversed;

    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.logo {
    margin: 30px;
    text-align: center;
}

.logo img {
    max-width: 100%;
}

.box-types {
    display: flex;
    flex-direction: column;
}

.box-types--label {
    color:#fff;
    font-size: 17px;
    font-weight: bold;
    text-align: center;
}

.order-type-list {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 10px 3px 10px;
}

.order-type-item {
    display: flex;
    flex-direction: column;
    background: transparent;
    border-radius: 8px;
    margin: 5px;
    border: 1px solid #fff;
    height: 135px;
    justify-content: space-between;
    flex-grow: 1;
    flex-basis: 0;
    max-width: 110px;
    cursor: pointer;
}

.order-type-item .icon {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.order-type-item span {
    font-size: 10px;
    font-weight: bold;
    line-height: 1.4;
    color: #fff;
    padding: 0 5px;
    height: 40px;
    text-align: center;
    text-transform: uppercase;
}

.order-type-list-scroll {
    overflow-x: auto;
    display: flex;
    margin: 0 0 20px 0;
    justify-content: space-around;
}

.order-type-list-scroll .order-type-item {
    flex: none !important;;
}

.hotline {
        border: 1px solid #fff;
    border-radius: 10px;
    margin: 0 auto 40px;
}

.hotline span {
    font-size: 16px;
    color:#fff;
    display: inline-block;
    padding: 0;
}
.hotline i {
    color:#fff;
    padding: 0 7px 0 0;
}

.box-qr-info {
    background-color: #0071E5!important;
    text-align: center;
}

.box-qr-info,
.v-card__text {
    color: #fff!important;
}

.table-number-input {
    text-align: center;
    font-size: 50px;
    color: #fff;
    outline: none;
    display: block;
    width: 100%;
}

.table-number-input::placeholder
{
    color: #fff;
}

.btn-row 
{
    display: flex;
}

.btn-row-item
{
    flex: 50%;
    margin: 0 5px 40px;
    display: flex;
    align-items: baseline;
    justify-content: center;
}

.btn-row-item span {
    padding: 12px 0 10px;
}
</style>