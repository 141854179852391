<template>
    <div class="page-zagruzka">
        <TopBar title="Загруженность кафе"  :showBack="true"  class="action-bar" />
        <v-overlay :value="proccessing">
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>
        <div class="zagruzka-scrollview scrollbar-hidden">
            <div class="p-title-wrap">
                <img src="../assets/icon-type-svetofor-w.png" height="56px" class="p-title-img"/>
                <span class="p-title">
                    Загруженность <br>кафе <br>{{restoran.name}}
                </span>
            </div>
            <div v-if="!proccessing">
                <div  class="d-flex justify-center align-center" style="margin:40px 0 40px;"  v-if="!showMore">
                    <img src="../assets/zagruzka-stol.png" width="85px"
                        style="margin:0 14px 0 0" />
                    <div class="text-center">
                        <span class="zagruzka-info">Свободных столов</span>
                        <div :style="{color:restoranInfo.zagruzkaColor}" class="zagruzka-cifr" >
                            <span style="font-size:20px">{{restoranInfo.zagruzkaText}}</span>
                            <!-- <span style="font-size:30px">{{restoranInfo.zagruzkaPerc}}</span>
                            <span style="font-size:20px">%</span> -->
                        </div>
                    </div>
                </div>
                <div  class="d-flex justify-center align-center" style="margin:40px 0 40px;" v-if="!showMore" >
                    <img src="../assets/icon-zagruzka-time.png" width="85px"
                        style="margin:0 14px 0 0" />
                    <div>
                        <span class="zagruzka-info">
                            среднее  Время <br>ожидания блюда
                        </span>
                        <div :style="{color:restoranInfo.timeColor}" class="zagruzka-cifr" >
                            <span style="font-size:30px">{{restoranInfo.timeSredn}}</span>
                            <span style="font-size:20px">минут</span>
                        </div>
                    </div>
                </div>
                <div class="btn-more" @click="onTapMore" 
                    v-show="!showMore" >
                    Посмотреть время ожидания и стоп лист
                </div>
                <div v-show="showMore">
                    <div v-for="(group, i) in stopList" :key="i" class="group-item" >
                        <div class="group-item-name">{{group.name}}</div>
                        <div v-for="(section, j) in group.sections" :key="j" class="section-item">
                            <div class="d-flex section-item-title" @click="onTapSection(section)">
                                <div class="section-item-name">- {{section.name}}</div>
                                <div v-if="section.time"  :style="{color:section.timeColor}" class="section-item-time">{{section.time}}</div>
                                <div class="section-item-arrow">
                                    <i class="fas fa-chevron-right"></i>
                                </div>
                            </div>
                            <div class="section-item-list" v-if="section.visible">
                                <div v-for="(item, k) in section.items" :key="k" class="d-flex section-dish">
                                    <div class="section-dish-name" :class="{stopped:item.stop}">{{item.name}}</div>
                                    <div v-if="item.text" :style="{color:item.textColor}" class="section-dish-text">{{item.text}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <router-link to="/zagruzka/rest_list"  class="btn-over">Выбрать другое кафе</router-link>
    </div>
</template>

<script>
import TopBar from '../components/TopBar.vue';
export default {
    components: {TopBar},
    data(){
        return {
            restoran: {},
            proccessing: false,
            showMore: false,
            restoranInfo: {
                zagruzkaPerc: 65,
                zagruzkaColor: '#219653',
                timeSredn: 7,
                timeColor: '#219653',
            },
            stopList: [
                {
                    name: 'КАФЕ',
                    sections: [
                        {
                            name: 'ВЫПЕЧКА, КУТАБЫ',
                            time: '2 мин',
                            timeColor: '#219653',
                            items: [
                                {
                                    name: 'Булочка "8 злаков" 55 г',
                                    stop: false,
                                    text: null,
                                    textColor: null,
                                },
                                {
                                    name: 'Булочка "Французкая" 55 г',
                                    stop: true,
                                    text: 'в стоп-листе',
                                    textColor: '#DF1317',
                                },
                                {
                                    name: 'Кутаб с креветкой',
                                    text: 'в стоп-листе',
                                    textColor: '#DF1317',
                                }
                            ]
                        },
                        {
                            name: 'РЫБА НА ГРИЛЕ',
                            time: '20 мин',
                            timeColor: '#DF1317',
                            items: [
                                {
                                    name: 'Булочка "8 злаков" 55 г',
                                },
                                {
                                    name: 'Булочка "Французкая" 55 г',
                                    text: 'в стоп-листе',
                                    textColor: '#DF1317',
                                },
                                {
                                    name: 'Кутаб с креветкой',
                                    text: 'в стоп-листе',
                                    textColor: '#DF1317',
                                }
                            ]
                        },
                        {
                            name: 'ГОРЯЧИЕ БЛЮДА',
                            time: '7 мин',
                            timeColor: '#219653',
                            items: [
                                {
                                    name: 'Булочка "8 злаков" 55 г'
                                },
                                {
                                    name: 'Булочка "Французкая" 55 г',
                                    text: 'в стоп-листе',
                                    textColor: '#DF1317',
                                },
                                {
                                    name: 'Кутаб с креветкой',
                                    text: 'в стоп-листе',
                                    textColor: '#DF1317',
                                }
                            ]
                        }
                    ]
                },
                {
                    name: 'Магазин',
                    sections: [
                        {
                            name: 'КУЛИНАРИЯ',
                            items: [
                                {
                                    name: 'Булочка "8 злаков" 55 г'
                                },
                                {
                                    name: 'Булочка "Французкая" 55 г',
                                    text: 'в стоп-листе',
                                    textColor: '#DF1317',
                                },
                                {
                                    name: 'Кутаб с креветкой',
                                    text: 'в стоп-листе',
                                    textColor: '#DF1317',
                                }
                            ]
                        },
                        {
                            name: 'РЫБА НА ГРИЛЕ',
                            time: '20 мин',
                            timeColor: '#DF1317',
                            items: [
                                {
                                    name: 'Булочка "8 злаков" 55 г'
                                },
                                {
                                    name: 'Булочка "Французкая" 55 г',
                                    text: 'в стоп-листе',
                                    textColor: '#DF1317',
                                },
                                {
                                    name: 'Кутаб с креветкой',
                                    text: 'в стоп-листе',
                                    textColor: '#DF1317',
                                }
                            ]
                        },
                        {
                            name: 'ГОРЯЧИЕ БЛЮДА',
                            time: '7 мин',
                            timeColor: '#219653',
                            items: [
                                {
                                    name: 'Булочка "8 злаков" 55 г'
                                },
                                {
                                    name: 'Булочка "Французкая" 55 г',
                                    text: 'в стоп-листе',
                                    textColor: '#DF1317',
                                },
                                {
                                    name: 'Кутаб с креветкой',
                                    text: 'в стоп-листе',
                                    textColor: '#DF1317',
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    },
    created(){
        this.getRestorans().then(() => {
            const rest = this.$store.state.restorans.filter(item => item.id == this.$route.params.fs_uid)[0];

            this.restoran = rest;
        })

        this.loadData();
    },
    methods: {
        onTapMore(){
            this.showMore = true;
        },
        onTapSection(section){
            if(typeof section.visible == 'undefined'){
                this.$set(section, 'visible', true)
            }else{
                section.visible = !section.visible
            }
        },
        loadData(){
            if(this.proccessing) return;
            this.proccessing = true;

            this.restoranInfo = {};
            this.stopList = [];

            this.request({
                action: 'zagruzkaLoadInfo',
                fields: {
                    time: this.time,
                    fs_uid: this.$route.params.fs_uid
                }
            }).then(r => {
                this.proccessing = false;
                this.restoranInfo = r.restoranInfo;
                this.stopList = r.stopList;
            }).catch(() => {
                this.proccessing = false;
                alert('Ошибка запроса');
            })
        }
    }
}
</script>

<style scoped>
.page-zagruzka {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.action-bar {
    flex: 0 0 60px;
}

.btn-over {
    display: block;
    background: #0071E5;
    color: #fff;
    text-decoration: none;
    text-align: center;
    font-weight: 700;
    font-size: 12px;
    text-transform: uppercase;
    padding: 12px;
}

.zagruzka-scrollview {
    flex-grow: 1;
    overflow: auto;
} 

.p-title-wrap {
    display: flex;
    margin: 20px 0;
    justify-content: center;
    align-items: center;
}

.p-title {
    font-size: 20px;
    font-weight: 700;
    color: #333;
    text-align: center;
    text-transform: uppercase;
}

.zagruzka-info {
    font-weight: 700;
    text-align: center;
    text-transform:uppercase;
    margin:0 0 5px 0;
    font-size:18px;
    color:#000;
    display: block;
}

.zagruzka-cifr {
    font-weight: 700;
    text-align: center;
}

.btn-more {
    background: #0071E5;
    color: #fff;
    text-align: center;
    font-weight: 700;
    font-size: 12px;
    text-transform: uppercase;
    margin: 12px;
    padding: 10px;
    border-radius: 50px;
    cursor: pointer;
}

.btn-over {
    background: #0071E5;
    color: #fff;
    text-wrap: true;
    text-align: center;
    font-weight: 700;
    font-size: 12;
    text-transform: uppercase;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding: 12;
}

.group-item-name {
    font-size: 16px;
    color: #333;
    text-transform: uppercase;
    font-weight: 700;
    padding: 14px 24px 7px 24px;
    border-bottom: 1px solid #F0F0F0;
}

.section-item {
    border-bottom: 1px solid #F0F0F0;
}

.section-item-arrow {
    color: #5C5C5C;
    font-size: 14px;
    margin: 15px 14px;
}

.section-item-name {
    text-transform: uppercase;
    font-size: 14px;
    color: #333;
    padding: 16px 16px 7px 34px;
    font-weight: 500;    flex-grow: 1;
}

.section-item-time {
    background: #E7E7E7;
    border-radius: 30px;
    padding: 7px 10px;
    margin: auto 16px auto 0;
    font-size: 12px;
}

.section-item-list {
    background: #F2F2F2;
}

.section-dish-name {
    margin: 9px 18px 9px 41px;
    font-size: 13px;
    color: #000;
    flex-grow: 1;

}

.section-dish-name.stopped {
    text-decoration: line-through;
    color: #506883;
}

.section-dish-text {
    font-size: 12px;
    margin: 0 49px 0 0;
    align-self: center;
}

</style>