<template>
    <div class="shop-menu-toggler">
        <div class="menu-container">
        <div @click="setvalue(0)" :class="{'menu-type-active':value==0, 'menu-type-inactive':value!==0}">
            <img src="../assets/icon-rest-toggler.png" style="margin:0 15px 0 0;height:20px" />
            <span>КАФЕ</span>
        </div>
        <div @click="setvalue(1)" :class="{'menu-type-active':value==1, 'menu-type-inactive':value!==1}">
            <img src="../assets/icon-cart-toggler.png" style="margin:0 15px 0 0;height:20px" />
            <span>МАГАЗИН</span>
        </div>
        <div @click="setvalue(2)" :class="{'menu-type-active':value==2, 'menu-type-inactive':value!==2}">
            <img src="../assets/icon-wineglass-toggler.png" style="margin:0 15px 0 0;height:20px" />
            <span>БАР</span>
        </div>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        value(){
            return this.$store.state.is_shop
        }
    },
    methods: {
        setvalue(val){
            this.$store.state.is_shop = val;
        }
    }
}
</script>

<style scoped>
.shop-menu-toggler {
    position: fixed;
    top: 60px;
    background: #fff;
    left: 0;
    right: 0;
    z-index: +2;
}


.menu-container
{
    height:40px;
    margin:3px 5px 0 5px;
    background-color: #006bd6;
    border-radius: 0 0 10px 10px;
    font-size: 15px;
    display: flex;
    flex-direction: row;
    justify-content: stretch;
}

.menu-type-active
{
    background-color: #01315d;
    border-radius: 0 0 10px 10px;
    /* font-size: 13px; */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 10px;
    flex: 1 1 auto;
}

.menu-type-active span
{
    color: #fff;
}

.menu-type-inactive
{
    background-color: #006bd6;
    border-radius: 0 0 10px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 10px;
    flex: 1 1 auto;
}

.menu-type-inactive span
{
    color: #fff;
}
</style>